<template>
  <nav class="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme " 
  id="layout-navbar">
    <div class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
      <a class="nav-item nav-link px-0 me-xl-4" href="javascript:void(0)">
        <i class="bx bx-menu bx-sm"></i>
      </a>
    </div>

    <div class="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
      <!-- /Search -->

      <ul class="navbar-nav flex-row align-items-center ms-auto">
        
         <li class="nav-item lh-1 me-3">
          <b>
            {{ user.name }} <span class="text-secondary fs-6">( {{ user.businessNature }})</span>
          </b>

          <div style="margin-top:5px ;">
            <small>
              {{ user.email }}
            </small>
          </div>
        </li> 

        <!-- User -->
        <li class="nav-item navbar-dropdown dropdown-user dropdown">
          <a class="nav-link dropdown-toggle hide-arrow" href="javascript:void(0);" data-bs-toggle="dropdown">
            <div class="avatar avatar-online">
              <img src="/assets/img/avatars/1.png" class="w-px-40 h-auto rounded-circle" />
            </div>
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            <li>
              <router-link class="dropdown-item" to="/">
                <div class="d-flex">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar avatar-online">
                      <img src="/assets/img/avatars/1.png" class="w-px-40 h-auto rounded-circle" />
                    </div>
                  </div>
                  <div class="flex-grow-1">
                   <span class="fw-medium d-block"> {{ user.name }}</span>
                    <small class="text-muted">{{ user.businessNature }}</small> 
                  </div>
                </div>
              </router-link>
            </li>
            <!-- <li>
              <div class="dropdown-divider"></div>
            </li> -->
            <!-- <li>
              <router-link class="dropdown-item" to="/profile">
                <i class="bx bx-user me-2"></i>
                <span class="align-middle">My Profile</span>
              </router-link>
            </li> -->
            <li>
              <div class="dropdown-divider"></div>
            </li>
            <li>
              <a class="dropdown-item" href="#" @click="confirmLogout">
                <i class="bx bx-power-off me-2"></i>
                <span class="align-middle">Log Out</span>
              </a>
            </li>
          </ul>
        </li>
        <!--/ User -->
      </ul>
    </div>
  </nav>
  <!-- Spinner -->
  <Spinner :loading="isLoading" />
</template>


<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import Spinner from '@/components/common/Spinner.vue';

export default {
  name: 'Header',
  components: {
    Spinner,
  },
  data() {
    return {
      loading:false,
      user: {},
      isLoading: false,
    };
  },
  methods: {
    profileCompleateORNot() {
      this.user = JSON.parse(localStorage.getItem('userData'));
      this.role = JSON.parse(localStorage.getItem('role'));
    },
    isProfileAccessible() {
      const { status } = this.user;
      return status === 'Pending' || status === 'Not Approved' || status === 'Approval' || status === 'Approved';
    },
    confirmLogout() {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will be logged out!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, log me out!',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.isConfirmed) {
          this.logout();
        }
      });
    },
    logout() {
      this.isLoading = true;
      axios.post('admin/logout', {}, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken')
        }
      })
        .then(response => {
          this.clearLocalStorage();
          this.$router.push({ name: 'Login' });
        })
        .catch(error => {
          console.error('Error logging out:', error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    clearLocalStorage() {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('userData');
      localStorage.removeItem('role');
      localStorage.removeItem("bookingDetails");
      localStorage.removeItem("containerDetail");
      localStorage.removeItem("bookingId");
      localStorage.removeItem("bookingNo");
      localStorage.removeItem("container");
    },
    // handleBeforeUnload() {
    //   localStorage.removeItem('accessToken');
    //   localStorage.removeItem('userData');
    //   localStorage.removeItem('role');
    //   localStorage.removeItem("bookingDetails");
    //   localStorage.removeItem("containerDetail");
    //   localStorage.removeItem("bookingId");
    //   localStorage.removeItem("bookingNo");
    //   localStorage.removeItem("container");
    // }
  },
  created() {
    this.profileCompleateORNot();
    // window.addEventListener('beforeunload', this.handleBeforeUnload);
  },
  // unmounted() {
  //   window.removeEventListener('beforeunload', this.handleBeforeUnload);
  // }
};
</script>

<style scoped>
.nav-item-hover {
  transition: 1s;
  border-radius: 5px;
  padding: 10px;
}

.nav-item-hover:hover {
  background-color: #1c396417;
  padding: 10px;
}

.active {
  background-color: #1c39643a;
}



.conditional-styles {
  /* Apply your styles here */

  max-width: 100% !important;
  margin: 0px !important;
}

.conditional-styles {
  /* Apply your styles here */

  width: 100% !important;
  margin: 0.75rem auto 0;
  border-radius: 0.375rem;
  padding: 0 1.5rem;
}
</style>
